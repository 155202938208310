import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods, FieldErrors } from 'react-hook-form';
import { useParams, NavLink } from 'react-router-dom';
import * as yup from 'yup';
import PasswordChecklist from 'react-password-checklist';
import { RegisterData } from 'services/rest/authentication';

import {
  StyledForm,
  PoweredByWrapper,
  NoAccountSection,
  RegisterText,
  StyledCheckBoxTerms,
  LogoWrapper
} from './Styled';
import { InputWrapper } from 'components/inputs/VerticalWrapper';
import Button from 'components/buttons/Button';
import { BrowsersFooter } from 'components/Footer';
import AdvocatesProLogo from 'images/logos/advocates_logo.svg';
import Checkbox from 'components/inputs/checkbox/Checkbox';
import ThreeDots from 'components/loaders/ThreeDots';
import { useSelector } from 'react-redux';
import { selectCompanyStyle } from 'redux/slices/CompanyStyle/CompanyStyleSlice';

interface Email {
  email: string;
}
export interface RegisterFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<RegisterData>;
  register: UseFormMethods['register'];
  loader: any;
  showPassword: boolean;
  onClickEye: any;
}

export const userSchema = yup.object().shape({
  email: yup.string().required('required').email('email')
});

const RegisterForm: React.FC<RegisterFormProps> = props => {
  const [checked, setChecked] = useState(false);
  const [checkedPolicies, setCheckedPolicies] = useState(false);
  const {
    errors,
    onSubmit,
    register,
    loader,
    showPassword,
    onClickEye
  } = props;

  const { t } = useTranslation(['register_form']);
  const { email } = useParams<Email>();

  const { companyStyle, loading } = useSelector(selectCompanyStyle);
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [validPassword, setValidPassword] = useState(false);

  return (
    <>
      <NavLink to="/" className="logo">
        <LogoWrapper>
          {loading ? (
            <ThreeDots />
          ) : (
            <>
              <img
                className="hide-on-mobile"
                src={companyStyle?.logo}
                alt="Home"
              />
              <img
                className="show-on-mobile mobile-logo"
                src={companyStyle?.logo}
                alt="Home"
              />
            </>
          )}
        </LogoWrapper>
      </NavLink>

      <RegisterText>
        <p>{t('register_form:register')}</p>
      </RegisterText>

      <StyledForm onSubmit={onSubmit}>
        <InputWrapper
          label={t('register_form:email')}
          value={email}
          name="email"
          ref={register}
          type="text"
          error={errors.email?.message}
          data-testid="email"
        />

        <InputWrapper
          label={t('register_form:password')}
          name="password"
          type="password"
          ref={register}
          error={errors.password?.message}
          data-testid="password"
          onClickEye={onClickEye}
          showPassword={showPassword}
          onChange={e => setPassword(e.target.value)}
        />

        <InputWrapper
          label={t('register_form:confirm_password')}
          name="confirmPassword"
          type="password"
          ref={register}
          error={errors.confirmPassword?.message}
          data-testid="confirmPassword"
          className="confirm-password"
          onClickEye={onClickEye}
          showPassword={showPassword}
          onChange={e => setPasswordAgain(e.target.value)}
        />
        <PasswordChecklist
          rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
          minLength={8}
          value={password}
          valueAgain={passwordAgain}
          messages={{
            minLength: 'La contraseña tiene más de 8 caracteres.',
            specialChar: 'La contraseña tiene caracteres especiales.',
            number: 'La contraseña tiene un número.',
            capital: 'La contraseña tiene una letra mayúscula.',
            match: 'Las contraseñas coinciden.'
          }}
          onChange={isValid => setValidPassword(isValid)}
        />
        <StyledCheckBoxTerms>
          <Checkbox
            id="terms"
            checked={checked}
            onClick={() => setChecked(!checked)}
          />
          <label>
            <p>
              {t('register_form:accept')}
              <NavLink to="/legal" target="_blank" className="register">
                {t('register_form:terms')}
              </NavLink>
            </p>
          </label>
        </StyledCheckBoxTerms>
        <StyledCheckBoxTerms>
          <Checkbox
            id="terms"
            checked={checkedPolicies}
            onClick={() => setCheckedPolicies(!checkedPolicies)}
          />
          <label>
            <p>
              {t('register_form:accept')}
              <NavLink to="/legal" target="_blank" className="register">
                {t('register_form:policies')}
              </NavLink>
            </p>
          </label>
        </StyledCheckBoxTerms>

        <Button
          value={t('register_form:register_me')}
          variant="primary"
          onClick={() => onSubmit}
          loader={loader}
          disabled={!(checked && checkedPolicies && validPassword)}
        />
      </StyledForm>

      <NoAccountSection>
        <p>{t('register_form:have_account')}</p>
        <NavLink to="/sign_in" className="register">
          {t('register_form:sign_in')}
        </NavLink>
      </NoAccountSection>

      <PoweredByWrapper>
        <p>Powered By</p>
        <img src={AdvocatesProLogo} alt="AdvocatesPro" />
      </PoweredByWrapper>

      <BrowsersFooter />
    </>
  );
};

export default RegisterForm;
