import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { CircleIcons, CardNetworkStyled, Success } from './Styled';
import Icon from 'components/icons/Icon';
import Button from 'components/buttons/Button';
import { urlFacebook, urlLinkedin } from 'constants/setup.constants';
import { setRouterCurrent } from 'redux/slices/Router/RouterSlice';
import StatusIcon from 'components/icons/StatusIcon';
import { TwitterToken } from 'services/rest/Network/twitterToken';
import { TiktokToken } from 'services/rest/Network/getAuthToken';
import {
  selectUser,
  setUserInfo,
  setUserInfoLoading
} from 'redux/slices/userSlice';
import { Colors } from 'styles/Constants';
import { postNetworkToken } from 'services/rest/Network/Network';
import { getInfoUser } from 'services/rest/logout';
import InstagramLogo from 'images/logos/logo-instragram.png';
import Spinner from 'components/Spinner/Spinner';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export interface NetworkParticipationBoxProps {
  items: string;
  status: boolean;
  setUpdateError: any;
}

const BottonBoxNetwork: React.FC<NetworkParticipationBoxProps> = props => {
  const { items: network, status, setUpdateError } = props;
  const dispatch = useDispatch();
  const { company } = useSelector(selectCompany);
  const { currentUser } = useSelector(selectUser);
  const { pathname } = useLocation();
  dispatch(setRouterCurrent(pathname));
  const { t } = useTranslation(['social_network']);
  const [loader, setLoader] = useState<string | null>(null);

  const getData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string
  ) => {
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName
      },
      network
    )
      .then(response => {
        dispatch(setUserInfoLoading(true));
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              dispatch(setUserInfoLoading(false));
            }
          );
        }
      })
      .catch(error => {
        setUpdateError(t('social_network:error_connecting'));
        dispatch(setUserInfoLoading(false));
      });
  };

  const conect = (conect: string) => {
    setLoader(conect);
    switch (conect) {
      case 'facebook':
        setLoader(null);
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );
        break;
      case 'twitter':
        TwitterToken(currentUser?.token)
          .then(response => {
            setLoader(null);
            window.location.href = response.data.data;
          })
          .catch(error => {
            setLoader(null);
            setUpdateError(t('social_network:error_connecting'));
          });
        break;
      case 'instagram':
        Swal.fire({
          color: Colors.secondaryDarkBlue,
          width: 400,
          imageUrl: InstagramLogo,
          imageWidth: 200,
          imageHeight: 100,
          input: 'text',
          showCloseButton: true,
          inputLabel: t('social_network:UserName'),
          inputPlaceholder: 'Enter your email address',
          confirmButtonColor: Colors.secondaryPurple
        }).then(result => {
          setLoader(null);
          getData(conect, 'code', '', '', result.value);
        });
        break;
      case 'linkedin':
        setLoader(null);
        window.location.href = urlLinkedin('78xnlxviahs2ca', conect);
        break;
      case 'tiktok':
        TiktokToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));

        break;
    }
  };

  return (
    <CardNetworkStyled>
      <CircleIcons className={status ? `${network}-active` : `block`}>
        {status && (
          <Success>
            <StatusIcon variant={'success'} />
          </Success>
        )}
        <Icon name={network} size={'small'} color={'#FFF'} />
      </CircleIcons>
      <p>{network}</p>
      {!status && (
        <>
          {loader === network ? (
            <Spinner size={'small'} />
          ) : (
            <Button
              size="small"
              value={t('social_network:Connect')}
              variant={network}
              onClick={() => conect(network)}
            />
          )}
        </>
      )}
    </CardNetworkStyled>
  );
};

export default BottonBoxNetwork;
