import React, {
  useState, //
  useMemo,
  useRef,
  MouseEvent,
  RefObject,
  useEffect
} from 'react';

import cx from 'classnames';
import {
  DateRange, //
  OnChangeProps,
  Range,
  RangeFocus
} from 'react-date-range';

import CalendarWrapper, { RefreshButton } from './StyledCalendar';
import CalendarInput from './CalendarInput';
import useOutsideClick from 'hooks/useOutsideClick';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { AvailableLocales, chooseLocale } from 'helpers/dateHelpers';

export interface CalendarProps {
  locale?: AvailableLocales;
  withinSameMonth?: boolean;
  startDate?: Date;
  endDate?: Date;
  onChange?: (startDate: Date, endDate: Date) => void;
  defaultStartDate?: any;
  defaultEndDate?: any;
  editMode?: boolean;
  setStartDateValidation?: any;
  setEndDateValidation?: any;
  startDateValidation?: boolean;
  endDateValidation?: boolean;
  disabled?: boolean;
  editStarDate?: boolean;
  isPending?: boolean;
}

const isStartDateFocused = (focusNumber: number) => focusNumber === 0;
const isEndDateFocused = (focusNumber: number) => focusNumber === 1;

const Calendar: React.FC<CalendarProps> = props => {
  const {
    locale = 'es',
    withinSameMonth = false,
    startDate,
    endDate,
    onChange,
    defaultStartDate = null,
    defaultEndDate = null,
    editMode = false,
    setStartDateValidation,
    setEndDateValidation,
    startDateValidation = false,
    endDateValidation = false,
    disabled,
    editStarDate = false,
    isPending = false
  } = props;

  const [dates, setDates] = useState<Date[]>([]);
  const [open, setOpen] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [selected, setSelected] = useState(false);
  const [firstDay, setFirstDay] = useState<Date>();
  const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);
  const ref = useRef<HTMLElement | null>(null);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (defaultStartDate != null && defaultEndDate != null) {
      setDates([defaultStartDate, defaultEndDate]);
      setFirstDay(
        new Date(
          defaultStartDate?.getFullYear(),
          defaultStartDate?.getMonth(),
          1
        )
      );
    } else {
      setDates([]);
    }
  }, [defaultStartDate, defaultEndDate]);

  const selectionRanges = [
    {
      startDate: dates[0],
      endDate: dates[1],
      key: 'selection'
    }
  ];

  const handleSelect = (range: OnChangeProps) => {
    const { selection } = range as { selection: Range };
    let startDate = selection.startDate as Date;
    const endDate = selection.endDate as Date;
    const nDefaultStartDate = new Date(defaultStartDate);

    if (editMode && !editStarDate && !isPending) {
      if (
        startDate.getTime() ===
        new Date(nDefaultStartDate.setHours(0, 0)).getTime()
      ) {
        setOpen(true);
        setStartDateValidation(false);
      } else {
        setStartDateValidation(true);

        if (startDateValidation) {
          startDate = new Date(nDefaultStartDate.setHours(0, 0));
        }
      }
    }

    setDates([startDate, endDate]);

    if (isStartDateFocused(focusedRange[1])) {
      setSelecting(true);
      setSelected(false);
    }

    if (isEndDateFocused(focusedRange[1])) {
      setOpen(false);
      setSelecting(false);
      setSelected(true);
      onChange && onChange(startDate, endDate);
    }
  };

  const rdrNoSelection = useMemo(() => {
    return dates[0] === undefined && dates[1] === undefined;
  }, [dates]);

  const onClickCustomInput = (event: MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  };

  const onRefreshDates = () => {
    setDates([]);
    setSelecting(false);
    setSelected(false);
    setFocusedRange([0, 0]);
  };

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const enabledMonthAndYear = !selecting || !withinSameMonth;

  return (
    <CalendarWrapper ref={ref as RefObject<HTMLDivElement>} disabled={disabled}>
      <CalendarInput
        locale={locale}
        onClick={onClickCustomInput}
        open={open}
        dates={dates}
        disabled={disabled}
      />

      {open && selecting && (
        <RefreshButton onClick={onRefreshDates}>⟲ </RefreshButton>
      )}

      <DateRange
        locale={chooseLocale(locale)}
        className={cx({ rdrNoSelection, open, selected })}
        showDateDisplay={false}
        showMonthArrow={enabledMonthAndYear}
        showMonthAndYearPickers={enabledMonthAndYear}
        minDate={editStarDate ? new Date() : startDate}
        maxDate={new Date(currentYear + 1, 12, 0)}
        focusedRange={focusedRange}
        ranges={selectionRanges}
        onChange={handleSelect}
        onRangeFocusChange={setFocusedRange}
        moveRangeOnFirstSelection={true}
      />
    </CalendarWrapper>
  );
};

export default Calendar;
