import React, { useCallback, useMemo, useRef, useState } from 'react';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import { getOrganizationsLinkedin } from 'services/rest/Campaign/getOrganizationsLinkedin';
import '@draft-js-plugins/mention/lib/plugin.css';
import Counter from './Counter';
import { EditorWrapper } from './Styled';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import '@draft-js-plugins/hashtag/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import CustomHashtag from './Style/CustomHashtag';
import CustomMention from './Style/CustomMentions';
import CustomLinks from './Style/CustomLinks';

export interface EditorInputProps {
  className?: string;
  placeholder?: string;
  content?: string;
  onChange?: (content: string) => void;
  maxCharacters?: number;
  ref?: any;
  isTwitter: boolean;
  disabled?: boolean;
}

const getInitialEditorState = (content?: string) => {
  if (content && content.trim() !== '') {
    const contentState = createContentStateWithEntities(content);
    return EditorState.createWithContent(contentState);
  }

  return EditorState.createEmpty();
};

const createContentStateWithEntities = (text: string) => {
  const mentionRegex = /@\[([^\]]+)\]\((urn:[^)]+)\)/g;
  const lines = text.split('\n');
  let contentState = ContentState.createFromText('');

  lines.forEach((line, lineIndex) => {
    if (lineIndex > 0) {
      contentState = Modifier.splitBlock(
        contentState,
        contentState.getSelectionAfter()
      );
    }

    let matchArr;
    let lastIndex = 0;

    while ((matchArr = mentionRegex.exec(line)) !== null) {
      const start = matchArr.index;
      const end = start + matchArr[0].length;
      const entityText = matchArr[1];
      const entityMention = matchArr[0];

      if (start > lastIndex) {
        const textBefore = line.slice(lastIndex, start);
        contentState = Modifier.insertText(
          contentState,
          contentState.getSelectionAfter(),
          textBefore
        );
      }

      contentState = contentState.createEntity('mention', 'IMMUTABLE', {
        mention: { name: entityText, link: entityMention }
      });
      const entityKey = contentState.getLastCreatedEntityKey();

      contentState = Modifier.insertText(
        contentState,
        contentState.getSelectionAfter(),
        entityText,
        undefined,
        entityKey
      );

      lastIndex = end;
    }

    if (lastIndex < line.length) {
      const textAfter = line.slice(lastIndex);
      contentState = Modifier.insertText(
        contentState,
        contentState.getSelectionAfter(),
        textAfter
      );
    }
  });

  return contentState;
};

const EditorInputLinkedin: React.FC<EditorInputProps> = props => {
  const {
    placeholder,
    content,
    onChange,
    maxCharacters,
    className,
    ref,
    isTwitter,
    disabled
  } = props;

  const [editorState, setEditorState] = useState(() =>
    getInitialEditorState(content)
  );

  const [search, setSearch] = useState('');

  const editor = useRef<Editor | null>(null);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent: CustomMention,
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '@',
      supportWhitespace: true,
      mentionRegExp: '\\S+?', // Acepta cualquier carácter no-espacio
      mentionPrefix: '@'
    });
    const hashtagPlugin = createHashtagPlugin({
      hashtagComponent: CustomHashtag
    });
    const linksPlugin = createLinkifyPlugin({
      component: CustomLinks
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, hashtagPlugin, linksPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const { currentUser } = useSelector(selectUser);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const mapMentionToSuggestion = (mention: {
    name: string;
    imageUrl: string;
    mention: string;
  }) => ({
    name: mention.name,
    avatar: mention.imageUrl,
    link: mention.mention
  });

  React.useEffect(() => {
    if (!search) return;
    const getData = setTimeout(() => {
      getOrganizationsLinkedin(currentUser?.token, search)
        .then(mentions => {
          const { data } = mentions;
          if (Array.isArray(data)) {
            const suggestions = data.map(mapMentionToSuggestion);
            setSuggestions(suggestions);
          }
        })
        .catch(console.log);
    }, 800);

    return () => clearTimeout(getData);
  }, [search]);

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    if (!value) return;
    setSearch(value);
  }, []);

  const focusEditor = () => {
    editor.current && editor.current.focus();
  };

  const onEditorChange = useCallback(
    (newEditorState: EditorState) => {
      setEditorState(newEditorState);

      const contentState = newEditorState.getCurrentContent();

      const rawEditorContent = convertToRaw(contentState);
      const entityMap = rawEditorContent.entityMap;
      const blocks = rawEditorContent.blocks;

      let replacedText = '';

      blocks.forEach((block, blockIndex) => {
        let blockText = block.text;
        const entityRanges = block.entityRanges;

        entityRanges.sort((a, b) => b.offset - a.offset);

        entityRanges.forEach(range => {
          const entity = entityMap[range.key];
          if (entity.type === 'mention' && entity.data?.mention) {
            const start = range.offset;
            const end = start + range.length;
            const mentionLink = entity.data.mention.link;

            blockText =
              blockText.slice(0, start) + mentionLink + blockText.slice(end);
          }
        });

        replacedText += blockText;
        if (blockIndex < blocks.length - 1) replacedText += '\n';
      });

      if (onChange) {
        onChange(replacedText);
      }
    },
    [onChange]
  );

  const text = editorState.getCurrentContent().getPlainText('');

  return (
    <EditorWrapper
      className={className}
      onClick={focusEditor}
      disabled={disabled}
    >
      <Editor
        ref={ref}
        editorState={editorState}
        onChange={onEditorChange}
        placeholder={placeholder}
        plugins={plugins}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
      />
      {maxCharacters && (
        <Counter
          isTwitter={isTwitter}
          maxCharacters={maxCharacters}
          text={text}
        />
      )}
    </EditorWrapper>
  );
};

export default EditorInputLinkedin;
